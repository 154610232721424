import { useContext, useCallback } from 'react';

// Context
import { ChatbotContext } from '#contexts/chatbot';

// Reducers
import { chatbotAction } from '#reducers/chatbot';

// Adapters
import axios from '#adapters/axios';
import { fetchEventSource } from '@microsoft/fetch-event-source';

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST + "/api";

const useChatbot = () => {
  const { chatbot, dispatchChatbot } = useContext(ChatbotContext);

  const onToggleChat = useCallback((isOpen) => {
    dispatchChatbot({
      type: chatbotAction.TOGGLE_CHATBOT,
      payload: {
        isOpen
      }
    });
  }, [dispatchChatbot]);

  const initializeChatbot = useCallback(async (reInit = false) => {
    const URL = `${ BACKEND_HOST }/nudge-engine/conversation`;
    const STORAGE_KEY = 'conversationId';
    try {
      // Assume localStorage conversationId is correct, if it exists
      let conversationId = sessionStorage.getItem(STORAGE_KEY);
      if (!reInit && conversationId) {
        return dispatchChatbot({
          type: chatbotAction.INITIALIZE_CHATBOT,
          payload: {
            conversationId
          }
        });
      }
      const response = await axios.post(URL);
      const {
        _id
      } = response.data;
      sessionStorage.setItem(STORAGE_KEY, _id);
      dispatchChatbot({
        type: chatbotAction.INITIALIZE_CHATBOT,
        payload: {
          conversationId: _id
        }
      });
      return;
    } catch (error) {
      console.log("Error in initializeChatbot: ", error);
    }
  }, [dispatchChatbot]);

  const subscribeNotification = useCallback(async ({ agentCode, signal }) => {
    const URL = `${ BACKEND_HOST }/notification/events`;
    return fetchEventSource(URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        agentCode
      }),
      onmessage: (stream) => {
        const { content, conversationId } = JSON.parse(stream.data);
        if (content && conversationId) {
          sessionStorage.setItem('conversationId', conversationId);
          dispatchChatbot({
            type: chatbotAction.PUSH_NOTIFICATION,
            payload: {
              conversationId,
              notificationMessage: content
            }
          });
        }

      },
      onerror: (data) => {
        console.log("Subscription  error", data);
      },
      keepalive: true,
      signal,
      openWhenHidden: true
    });
  }, [dispatchChatbot]);
  const clearNotification = useCallback(() => {
    dispatchChatbot({
      type: chatbotAction.CLEAR_NOTIFICATION
    });
  }, [dispatchChatbot]);

  return {
    chatbot,
    onToggleChat,
    initializeChatbot,
    subscribeNotification,
    clearNotification
  };
};

export default useChatbot;