import '../styles/Timeout.scss';

const Timeout = () => {
  return (
    <div className="timeout-container">
      <div className="timeout-content">
        <h1>⏰ Service Timeout</h1>
        <p>No action detected within 30 mins. Please re-open the chatbot.</p>
      </div>
    </div>
  );
};

export default Timeout;
