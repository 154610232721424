import message from '#components/message';
import { chatbotMode } from '#config/config';

const chatHistoryAction = {
  INITIALIZE_CHATBOT: "INITIALIZE_CHATBOT",
  QUERY_START: "QUERY_START",
  QUERY_END: "QUERY_END",
  STREAMING_START: "STREAMING_START",
  STREAMING: "STREAMING",
  STREAMING_END: "STREAMING_END",
  TOOL_CALL_BEGIN: 'TOOL_CALL_BEGIN',
  TOOL_CALL_END: 'TOOL_CALL_END',
  STATE: 'STATE',
  FETCH_HISTORY: 'FETCH_HISTORY',
  QUERY_ERROR: 'QUERY_ERROR'
};

const chatHistoryInitialState = {
  status: chatbotMode.Idle,
  messages: []
};

const chatHistoryReducer = (state, action) => {
  switch (action.type) {
    // case 'fetch': {
    //   let newState = action.payload.conversations ?? [];
    //   const welcomeMessage = {
    //     role: 'system',
    //     content: {
    //       // text: `Welcome! Feel free to ask anything about ${ companyNameMapper[company] } and its products.`,
    //       resources: [
    //         {
    //           type: 'productCategories',
    //           data: action.payload.productMapping,
    //         },
    //       ],
    //     },
    //   };
    //   newState.push(welcomeMessage);
    //   return newState;
    // }
    // case 'append': {
    //   const newState = state.slice(); // shallow copy
    //   newState.push(action.payload); // append message
    //   return newState;
    // }
    // case 'update': {
    //   let newState = state.slice(); // shallow copy
    //   const { filter, payload } = action;
    //   let indexes = [];
    //   try {
    //     indexes = newState.reduce(
    //       (acc, chat, idx) => (filter(chat) ? [...acc, idx] : acc),
    //       []
    //     );
    //   } catch (error) {
    //     console.error(error);
    //     return state;
    //   }
    //   if (indexes.length === 0) {
    //     return state;
    //   }
    //   newState = newState.map((chat, idx) =>
    //     !indexes.includes(idx) ? chat : payload
    //   );
    //   return newState;
    // }
    // case 'stream': {
    //   const newState = state.slice(); // shallow copy
    //   const { streamId } = action.payload;
    //   const idx = newState.findIndex(
    //     (chat) => chat.streamId === streamId
    //   );
    //   if (idx === -1) {
    //     return state;
    //   }
    //   newState[idx].messageId = action.payload.messageId;
    //   newState[idx].content.text += action.payload.content.text;
    //   newState[idx].status = 'streaming';
    //   return newState;
    // }
    // case 'clear': {
    //   return [];
    // }
    // case 'cross-check': {
    //   // not implemented yet. maybe use lodash differenceBy() if necessary
    //   return state;
    // }
    case chatHistoryAction.QUERY_START: {
      const { query } = action.payload;
      const isShouldAppendWaiting = /clear message/i.test(query) === false;
      return {
        ...state,
        status: isShouldAppendWaiting ? chatbotMode.Streaming : chatbotMode.Idle,
        messages: [
          ...state.messages,
          {
            role: 'user',
            content: {
              text: query,
              type: 'message',
            },
          },
          // ...(isShouldAppendWaiting ? [{
          //   role: 'assistant',
          //   status: 'waiting',
          //   content: {
          //     type: 'message',
          //     text: ""
          //   }
          // }] : [])
        ]
      };
    }
    case chatHistoryAction.TOOL_CALL_BEGIN: {
      const { content, runId } = action.payload;
      const message = {
        role: 'assistant',
        status: 'done',
        content: {
          text: content,
          type: 'tool_call_start',
          runId: runId
        },
      };
      
      // state.messages[state.messages.length - 1] = message;
      const messages = [...state.messages, message]
      return {
        ...state,
        messages
      };
    }
    case chatHistoryAction.TOOL_CALL_END: {
      const { content, runId } = action.payload;

      const messages = [...state.messages];
      const idx = messages.findIndex(
        (message) => message.content.runId === runId
      );

      messages[idx] = {
        ...messages[idx],
        content: {
          ...messages[idx].content,
          type: 'tool_call_end',
          text: content
        }
      }

      return {
        ...state,
        messages
      };
    }
    case chatHistoryAction.STREAMING_START: {
      const { runId, tags } = action.payload;

      console.log("STREAMING_START", runId)
      const messages = [...state.messages];
      const toolStartMsgIdx = messages.findIndex((message) =>
        tags.includes(message.content.runId)
      );

      const insertIdx =
        toolStartMsgIdx === -1 ? messages.length : (toolStartMsgIdx + 1);

      messages.splice(insertIdx, 0, {
        role: 'assistant',
        status: 'streaming',
        content: {
          type: 'message',
          text: '',
          runId: runId,
          tags: tags,
        },
      });
      return {
        ...state,
        messages: messages
      };
    }
    case chatHistoryAction.STREAMING: {
      const { runId, tags, content } = action.payload;
      
      const messages = [...state.messages];
      const idx = messages.findIndex((message) => message.content.runId === runId)

      // streamMsg.content.text = streamMsg.content.text + content;

      messages[idx] = {
        ...messages[idx],
        content: {
          ...messages[idx].content,
          text: messages[idx].content.text + content
        }
      }

      // messages[messages.length - 1] = {
      //   ...messages[messages.length - 1],
      //   status: 'streaming',
      //   content: {
      //     ...messages[messages.length - 1].content,
      //     text: messages[messages.length - 1].content.text + content
      //   }
      // };
      return {
        ...state,
        messages
      };
    }
    case chatHistoryAction.STREAMING_END: {
      const { content, tags, runId } = action.payload;

      const messages = [...state.messages];
      const idx = messages.findIndex((message) => message.content.runId === runId)
      // streamMsg.content.text = content;
      // streamMsg.content.status = 'done';

      // if content is empty, remove that message
      if (!content) {
        messages.splice(idx, 1);
        return {
          ...state,
          messages
        };
      }

      messages[idx] = {
        ...messages[idx],
        status: 'done',
        content: {
          ...messages[idx].content,
          text: content,
        }
      }
      
      return {
        ...state,
        messages
      };
    }
    case chatHistoryAction.STATE: {
      const { messages } = action.payload;
      return {
        ...state,
        messages: messages
      };
    }
    case chatHistoryAction.FETCH_HISTORY: {
      const { messages } = action.payload;
      return {
        ...state,
        messages: messages
      };
    }
    case chatHistoryAction.QUERY_END: {
      return {
        ...state,
        status: chatbotMode.Idle
      };
    }
    case chatHistoryAction.QUERY_ERROR: {
      const messages = [...state.messages];
      messages[messages.length - 1].status = 'done';
      messages[messages.length - 1].content.type = "message";

      messages[messages.length - 1].content.text = "Sorry, I'm having trouble understanding you. Please try again.";
      return {
        ...state,
        messages
      };
    }
    default:
      return state;
  }
};

export {
  chatHistoryReducer,
  chatHistoryInitialState,
  chatHistoryAction
};
