import React, { useState, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Rout,
  redirect,
} from 'react-router-dom';
import './styles/common.scss';
import { ChatbotProvider } from '#contexts/chatbot';

// Pages
import Portal from '#pages/Portal';
import Csa from '#pages/csa';
import WaitingQueue from '#pages/WaitingQueue';
import NotFound from '#pages/NotFound';
import ConnectionFail from '#pages/ConnectionFail';
import Timeout from '#pages/Timeout';

import { socket } from './socket';

import { useChatbot } from '#hooks';

function App() {
  const [connectedWs, setConnectedWs] = useState(false);
  const [connectionFail, setConnectionFail] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="*" element={<NotFound />} />
        <Route path="demo/">
          <Route
            path=":company/:agentCode/portal"
            element={
              <ChatbotProvider>
                <Portal
                  connectedWs={connectedWs}
                  connectionFail={connectionFail}
                  isTimeout={isTimeout}
                />
              </ChatbotProvider>
            }
          />
          <Route path="csa" element={<Csa />} />
          <Route path="waiting-queue" element={<WaitingQueue />} />
          <Route path="connection-fail" element={<ConnectionFail />} />
          <Route path="timeout" element={<Timeout />} />
        </Route>
      </>
    ),
    {}
  );

  useEffect(() => {
    function onConnect() {
      console.log('Connected');
      setConnectedWs(true);
    }

    function onDisconnect(reason, details) {
      setConnectedWs(false);
      // the reason of the disconnection, for example "transport error"
      console.log(reason);
      // the low-level reason of the disconnection, for example "xhr post error"
      console.log(details?.message);
      // some additional description, for example the status code of the HTTP response
      console.log(details?.description);
      // some additional context, for example the XMLHttpRequest object
      console.log(details?.context);
    }

    function onConnectError(err) {
      // the reason of the error, for example "xhr poll error"
      console.log(err.message);

      // some additional description, for example the status code of the initial HTTP response
      console.log(err.description);

      // some additional context, for example the XMLHttpRequest object
      console.log(err.context);
    }

    function onReconnectFailed() {
      console.log('Reconnection failed');
      setConnectionFail(true);
    }

    function onTimeout() {
      console.log('Connection Timeout');
      setIsTimeout(true);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onConnectError);
    socket.io.on('reconnect_failed', onReconnectFailed);
    
    socket.on('timeout', onTimeout);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onDisconnect);
      socket.io.off('reconnect_failed', onReconnectFailed);
    };
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
