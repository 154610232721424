import '../styles/ConnectionFail.scss';

const ConnectionFail = () => {
  return (
    <div className="fail-container">
      <div className="fail-content">
        <h1>⚠️ Connection fail</h1>
        <p>This might be due to:</p>
        <ul>
          <li>Network issue (e.g. Network disconnected)</li>
          <li>Server issue</li>
        </ul>
        <p>Please come back later to access our services.</p>
      </div>
    </div>
  );
};

export default ConnectionFail;
