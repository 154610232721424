import React from 'react';

import userLogo from '../../assets/user-solid.svg';
import newChatLogo from '../../assets/new-chat.svg';
import './TitleBar.scss';
import { useChatbot } from '#hooks';


const TitleBar = ({ name, role, dialog, logout, mode, handleSetMode }) => {
const { chatbot, subscribeNotification, initializeChatbot } = useChatbot();

  return (
    <div className="title-bar">
      <div className="title-options-bar">
        <div className="title-left-group">
          {name ? (
            <div>
              <img className="user-logo" alt="user" src={userLogo}></img>
              {name}
            </div>
          ) : (
            <div>Welcome back!</div>
          )}
        </div>
        <div className="title-right-group">
          <button>
            <img
              className="user-logo"
              alt="user"
              src={newChatLogo}
              onClick={() => initializeChatbot(true)}
            ></img>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
