import '../styles/WaitingQueue.scss';

import aimLogo from '../assets/aim-logo.jpg';

const WaitingQueue = () => {
  return (
    <div className="waiting-page-container">
      <div className="waiting-container">
        <img src={aimLogo} alt="AIM-logo" className="top-image" />
        <div className="people-icon" aria-label="Cartoon people">
          <div className="person" aria-label="Person">
            <div className="head"></div>
            <div className="body"></div>
          </div>
          <div className="person" aria-label="Person">
            <div className="head"></div>
            <div className="body"></div>
          </div>
          <div className="person" aria-label="Person">
            <div className="head"></div>
            <div className="body"></div>
          </div>
          <div className="person" aria-label="Person">
            <div className="head"></div>
            <div className="body"></div>
          </div>
          <div className="person" aria-label="Person">
            <div className="head"></div>
            <div className="body"></div>
          </div>
        </div>
        <h1>Chatbot Busy!</h1>
        <p>Please come back later to access our services.</p>
      </div>
    </div>
  );
};

export default WaitingQueue;
